import { inject } from '@angular/core';
import { CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';

import { removeNilProperties } from '@clover/core/helpers/remove-nil-properties';

import { FilterableWorkflowStatus } from './state/workflows-state.model';
import { WorkflowsSelectors } from './state/workflows.selectors';

export enum WorkflowStatusRouteParam {
  All = 'all',
  Published = 'published',
  Draft = 'draft',
}

export const workflowStatusRoutesMap = new Map<WorkflowStatusRouteParam, FilterableWorkflowStatus>([
  [WorkflowStatusRouteParam.All, FilterableWorkflowStatus.All],
  [WorkflowStatusRouteParam.Published, FilterableWorkflowStatus.Published],
  [WorkflowStatusRouteParam.Draft, FilterableWorkflowStatus.Draft],
]);

export const workflowsListGuard: CanActivateFn = (route) => {
  const {
    queryParams: { query: queryParam, status: statusParam },
  } = route;

  const [queryValid, query] = validateQueryParam(queryParam);
  const [statusValid, status] = validateStatusParam(statusParam);

  if (queryValid && statusValid) return true;

  const segmentParams = {
    query,
    status,
  };

  return createUrlTreeFromSnapshot(route, [], removeNilProperties(segmentParams));
};

function validateQueryParam(param: string | undefined): [boolean, string] {
  return [true, param || inject(Store).selectSnapshot(WorkflowsSelectors.workflowsQuery)];
}

function validateStatusParam(param: WorkflowStatusRouteParam | undefined): [boolean, string] {
  const valid = workflowStatusRoutesMap.has(param);
  return [
    valid,
    valid ? param : workflowStatusToRouteParam(inject(Store).selectSnapshot(WorkflowsSelectors.workflowsStatus)),
  ];
}

export function workflowStatusToRouteParam(status: FilterableWorkflowStatus): WorkflowStatusRouteParam {
  for (const [param, value] of workflowStatusRoutesMap) {
    if (value === status) return param;
  }

  return WorkflowStatusRouteParam.All;
}
